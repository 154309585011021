import React, { useContext } from "react";
import "./MedicalNetwork.css";
import MNTable from "../components/MNTable";
import LanguageContext from "../contexts/LanguageContext";


// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "./HomePage.css";

const swiperImgs = require("../data/images.json")["medical_network_images"];
const imgBanner = require("../data/images.json")["banner_medical_network"];

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);



export default function MedicalNetwork() {
  const lang = useContext(LanguageContext);
  const direction = lang === "ar" ? "rtl" : "ltr";
  return (
    <main className="medicalNetwork" style={{direction: direction}}>
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          minHeight: "80vh",
        }}
      >
        <div className="container">
          <h1 className="title">
            {lang === "en" ? "Medical Network" : "الشبكة الطبية"}
          </h1>
          <p className="">
            {lang === "en"
              ? "A wide variety of medical services spans across 27 governorates"
              : "تتوسط الخدمات الطبية المتنوعة عبر 27 محافظة"}
          </p>
        </div>
      </section>
      <section className="content">
        <div className="table-section">
          <h1 style={{ direction: direction }}>
            {lang === "en" ? "Our Medical Network" : "شبكتنا الطبية"}
          </h1>
          <MNTable />
        </div>
      </section>
      <section
        style={{ flexDirection: "column" }}
        className="flex-container-global our-partners"
      >
        <h1 style={{ textAlign: "center", fontSize: "2em" }}>
          {lang === "en" ? "SOME OF OUR PARTNERS" : "بعض شركائنا"}
        </h1>
        <Swiper
          breakpoints={{
            1500: {
              width: 1500,
              slidesPerView: 3,
            },
            900: {
              width: 900,
              slidesPerView: 2,
            },
          }}
          slidesPerView={1}
          spaceBetween={5}
          loop={true}
          loopFillGroupWithBlank={false}
          autoplay={{
            delay: 4000,
          }}
          className="mySwiper"
        >
          {swiperImgs.map((img, i) => (
            <SwiperSlide className="flex" key={i}>
              <div className="swiper-img-container">
                <img src={img} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </main>
  );
}
