import React, { useContext, useState } from "react";
import LanguageContext from "../contexts/LanguageContext";
import "./Post.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/a11y/a11y.min.css";

const videoExt = [
  "mp4",
  "webm",
  "ogg", // only support these video formats
  "mov",
  "flv",
  "avi",
  "wmv",
  "m4v",
  "mpg",
  "mpeg",
];
// const imageExt = ["png", "jpeg", "jpg", "gif", "svg"];

export default function Post({ data: post }) {
  const lang = useContext(LanguageContext);
  const [readMore, setReadMore] = useState(false);

  return (
    <div className="post" dir={lang === "en" ? "ltr": "rtl"}>
      <div className="upper">
        <img src="/img/post-logo.svg" alt="logo" />
        <div className="text">
          <p>{lang === "en" ? "OmegaCare" : "أوميجا كير"}</p>
          <p style={{ color: "red" }}>
            {lang === "en" ? "Be Healthy" : "كن صحياًً"}
          </p>
        </div>
      </div>
      <div className="lower">
        <div className="media">
          <Swiper modules={[Pagination]} slidesPerView={1} pagination>
            {post.media.map((url, i) => {
              return (
                <SwiperSlide key={i}>
                  {videoExt.includes(
                    url.split(".")[url.split(".").length - 1]
                  ) ? (
                    <video className="postVideo" src={url} controls />
                  ) : (
                    <img className="postImage" src={url} alt="post" />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="text">
          <p className="title">{post.title[lang]}</p>
          <p>
            {readMore ? (
              <>
                {post.body[lang]}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "var(--primary-color1)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setReadMore(false);
                  }}
                >
                  {lang === "en" ? "Read less" : "أقرأ أقل"}
                </span>
              </>
            ) : post.body[lang].length < 100 ? (
              post.body[lang]
            ) : (
              <>
                {post.body[lang].slice(
                  0,
                  post.body[lang].split(" ", 20).join(" ").length
                )}
                ...&ensp;
                <span
                  style={{
                    textDecoration: "underline",
                    color: "var(--primary-color1)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setReadMore(true);
                  }}
                >
                  {lang === "en" ? " Read more" : "أقرأ المزيد"}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
