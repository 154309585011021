import React from 'react';
const img = require('./../data/images.json')['404'];

export default function ErrorPage({lang, error}) {
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <main className="errorPage" style={{
        minHeight: '80vh',
        direction: direction,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div className="container">
          <img src={img} alt="404" className='img' style={{
            height: '100%',
            paddingTop: '80px',
          }}/>
        </div>
    </main>
  );
}
