import React, { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";
import "./OurPlans.css";
import PlanCard from "./PlanCard";
import { Fade } from "react-awesome-reveal";

const plans = require("../data/plans.json");

export default function OurPlans() {
  const lang = useContext(LanguageContext);

  return (
    <section className="ourPlans">
      <div className="flex-container-global mid-section">
        <h1 style={{ color: `var(--primary-color1)` }}>
          {lang === "en" ? "OUR PLANS" : "خدماتنا"}
        </h1>
        <Fade>
          <div className="plans">
            {plans.map((plan, i) => {
              return <PlanCard i={i} lang={lang} plan={plan} key={plan.id} />;
            })}
          </div>
        </Fade>
      </div>
    </section>
  );
}
