import React, { useState, useContext } from "react";
import "./HomePageButtomSlider.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import LangaugeContext from "../contexts/LanguageContext";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default function HomePageButtomSlider() {
  let lang = useContext(LangaugeContext);
  const [open, setOpen] = useState(false);
  const [dialogImg, setDialogImg] = useState("");
  const ads = require("./../data/ads.json");
  const handleClickOpen = (i) => {
    // if (i < 23) setDialogImg(`/img/important_partner/${i + 1}/ad.png`);
    // else setDialogImg(`/img/important_partner/no_ad.png`);
    // else setDialogImg(`/img/important_partner/${i + 1}/logo.png`);
    setDialogImg(ads[i].ad)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    switch (reason) {
      case "cancel":
        break;
      case "ok":
        break;
      default:
        break;
    }
    setOpen(false);
  };

  return (
    <div className="homePageButtomSlider">
      <section
        style={{ flexDirection: "column" }}
        className="flex-container-global our-partners"
      >
        <h1 style={{ textAlign: "center", fontSize: "2em" }}>
          {lang === "en" ? "OUR MOST IMPORTANT PARTNERS" : "أهم شركائنا"}
        </h1>
        <Swiper
          breakpoints={{
            1500: {
              width: 1500,
              slidesPerView: 3,
            },
            900: {
              width: 900,
              slidesPerView: 2,
            },
          }}
          slidesPerView={1}
          spaceBetween={5}
          loop={true}
          loopFillGroupWithBlank={false}
          autoplay={{
            delay: 4000,
          }}
          className="mySwiper"
        >
          {ads.map((e, i) => (
            <SwiperSlide className="flex" key={i}>
              <div className="swiper-img-container">
                <img
                  className="important-partner-logo"
                  src={ads[i].logo}
                  // src={`/img/important_partner/${i + 1}/logo.png`}
                  alt="logo"
                  onClick={(e) => {
                    handleClickOpen(i);
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        dir={lang === "en" ? "ltr" : "rtl"}
      >
        <DialogTitle className="HomePageButtomSlider-Overlay">
          {lang === "en" ? "About Our Partner" : "معلومات عن شريكنا"}
        </DialogTitle>
        <DialogContent style={{ overflowX: "hidden" }}>
          <Box
            component="form"
            sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
          >
            <img className="important-partner-ad" src={dialogImg} alt="" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose(e, "ok")}>
            {lang === "en" ? "Ok" : "استمر"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
