import React, { useState, useContext, useEffect } from "react";
import "./Newsletter.css";
import Post from "../components/Post";
import Loading from "../components/Loading";
import { Button } from "@mui/material";

import LanguageContext from "../contexts/LanguageContext";

import { GetNews } from "../services/Server";

const imgBanner = require("../data/images.json")["banner_newsletter"];



export default function Newsletter() {
  const lang = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [offset, setOffset] = useState(0);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    GetNews(offset).then(res => {
      setPosts(oldPosts => [...oldPosts, ...res].filter((post, index, self) => self.findIndex(p => p._id === post._id) === index));
      setLoading(false);

    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError(err);
    });
    
  }, [offset]);

  const loadMore = () => {
    setLoading(true);
    setOffset(offset + 1);
  };

  return (
    <main
      className="newsletter"
      style={{
        minHeight: "80vh",
        direction: lang === "ar" ? "rtl" : "ltr",
        width: "100vw",
      }}
    >
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          minHeight: "80vh",
        }}
      >
        <div className="container">
          <h1 className="title">
            {lang === "en" ? "Newsletter" : " الأخبار"}
          </h1>
          <p className="">
            {lang === "en"
              ? "A live coverage of our news"
              : "تغطية حية لكل ما يخص شركة أوميجا كير "}
          </p>
        </div>
      </section>
      <div className="posts">
        {loading && (
          <Loading
            text={lang === "en" ? "Loading news" : "جاري تحميل الأخبار"}
          />
        )}
        {error && (
          <div>
            <br />
            <br />

            <p>
              {
                "Failed to load some or all data, please check your internet connection and refresh this page."
              }
            </p>
            <br />
            <p>
              If you believe this to be a bug, please contact
              bugs@omegacare-med.net
            </p>
            <br />
            <br />
            <br />
            <p dir="rtl">
              {
                "لم يتم تحميل بعض أو كل المعلومات، برجاء التأكد من أتصالك بالأنترنت ثم إعادة تحميل الصفحة."
              }
            </p>
            <br />
            <p dir="rtl">
              إن كنت متأكد من وجود مشكلة فالموقع، برجاء التواصل معنا على
              bugs@omegacare-med.net.
            </p>
            <br />
          </div>
        )}
        {posts &&
          posts.map((post, i) => {
            return <Post key={i} data={post} />;
          })}
      </div>
      <div className="bottomSection">
        {!(offset > posts.length - 1) && (
          <Button variant="contained" className="btn" onClick={loadMore}>
            {lang === "en" ? "Load more" : "حمل المزيد"}
          </Button>
        )}
      </div>
    </main>
  );
}
