import React, { useState, useContext } from "react";
import {
  Select, 
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import SearchBar from "./SearchBar";

import LangaugeContext from "../contexts/LanguageContext";
import "./MNTableFilters.css";

// todo: use to refactor the code
// function FormItem({label, value, onChange}){} 

export default function MNTableFilters({ setFiltersString, distincts }) {
  let lang = useContext(LangaugeContext);
  const [open, setOpen] = useState(false);

  const [governorate, setGovernorate] = useState("");
  const [region, setRegion] = useState("");
  const [branchType, setBranchType] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [level, setLevel] = useState("");

  const handleChange = ({ target }) => {
    switch (target.id) {
      case "governorate":
        setGovernorate(target.value);
        setRegion("");
        break;
      case "region":
        setRegion(target.value);
        break;
      case "branch_type":
        setBranchType(target.value);
        break;
      case "service_type":
        setServiceType(target.value);
        break;
      case "specialty":
        setSpecialty(target.value);
        break;
      case "level":
        setLevel(target.value);
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    switch (reason) {
      case "cancel":
        break;
      case "ok":
        let query = "";
        if (governorate !== "") query += `governorate=${governorate}&`;
        if (region !== "") query += `region=${region}&`;
        if (branchType !== "") query += `branch_type=${branchType}&`;
        if (serviceType !== "") query += `service_type=${serviceType}&`;
        if (specialty !== "") query += `specialty=${specialty}&`;
        if (level !== "") query += `level=${level}&`;
        if (serviceProvider !== "")
          query += `service_provider=${serviceProvider}&`;
        setFiltersString(query);
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const reset = () => {
    setGovernorate("");
    setRegion("");
    setBranchType("");
    setServiceType("");
    setSpecialty("");
    setLevel("");
    setServiceProvider("");
  };

  return (
    <div className="MNTableFilters">
      <Button
        variant="contained"
        disableElevation
        onClick={handleClickOpen}
        style={{ backgroundColor: "var(--primary-color1)", color: "white" }}
      >
        {lang === "en" ? "Filters" : "الفلاتر"}
      </Button>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        dir={lang === "en" ? "ltr" : "rtl"}
      >
        <DialogTitle className="MNTableFilters-Overlay">
          {lang === "en" ? "Filters" : "الفلاتر"}
        </DialogTitle>
        <DialogContent style={{ overflowX: "hidden" }}>
          <Box
            component="form"
            sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Governorate" : "المحافظة"}
              </InputLabel>
              <Select
                native
                value={governorate}
                onChange={handleChange}
                id="governorate"
                input={
                  <OutlinedInput label="Governorate" id="demo-dialog-native" />
                }
              >
                <option aria-label="None" value="" />
                {distincts.governorates.map((e, i) => (
                  <option key={i} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Region" : "المنطقة"}
              </InputLabel>
              <Select
                native
                disabled={governorate === ""}
                value={region}
                onChange={handleChange}
                id="region"
                input={<OutlinedInput label="Region" id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                {governorate !== "" &&
                  distincts.governorates
                    .find((e) => e.name === governorate)
                    .regions.map((region, i) => (
                      <option key={i} value={region}>
                        {region}
                      </option>
                    ))}
              </Select>
            </FormControl>

            <FormControl>
              <SearchBar
                style={{ height: 56, maxWidth: 426.5, margin: "0.5em 8px", backgroundColor: "white", border: "1px solid #BDBDBD" }}
                value={serviceProvider}
                setValue={setServiceProvider}
                onEnter={(e)=> {}}
                text={{ en: "Service Provider", ar: "مقدم الخدمة" }}
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Branch Type" : "نوع المركز"}
              </InputLabel>
              <Select
                native
                value={branchType}
                onChange={handleChange}
                id="branch_type"
                input={
                  <OutlinedInput label="Branch Type" id="demo-dialog-native" />
                }
              >
                <option aria-label="None" value="" />
                {distincts.branchTypes.map((e, i) => (
                  <option key={i} value={e}> {e} </option>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Service Type" : "نوع الخدمة"}
              </InputLabel>
              <Select
                native
                value={serviceType}
                onChange={handleChange}
                id="service_type"
                input={
                  <OutlinedInput label="Service Type" id="demo-dialog-native" />
                }
              >
                <option aria-label="None" value="" />
                {distincts.serviceTypes.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Speciality" : "التخصص"}
              </InputLabel>
              <Select
                native
                value={specialty}
                onChange={handleChange}
                id="specialty"
                input={
                  <OutlinedInput label="specialty" id="demo-dialog-native" />
                }
              >
                <option aria-label="None" value="" />
                {distincts.specialties.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="demo-dialog-native">
                {lang === "en" ? "Level" : "المستوى"}
              </InputLabel>
              <Select
                native
                value={level}
                onChange={handleChange}
                id="level"
                input={<OutlinedInput label="Level" id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                {distincts.levels.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>
            {lang === "en" ? "Reset" : "إعادة للأصل"}
          </Button>
          <Button onClick={(e) => handleClose(e, "cancel")}>
            {lang === "en" ? "Cancel" : "الغاء"}
          </Button>
          <Button onClick={(e) => handleClose(e, "ok")}>
            {lang === "en" ? "Ok" : "استمر"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
