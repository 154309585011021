const SERVER_HOST = "https://omegacare-server.testing.ahmedkira.com";
// const SERVER_HOST = "http://localhost:5000";

export const GetNews = async (offset=0) => {
    const response = await fetch(`${SERVER_HOST}/news?limit=1&offset=${offset}`);
    const data = await response.json();
    return data.data;
}

// export const GetMedicalNetwork = async (query) => {...}

export const GetMedicalNetworkOld = async (urlParams) => {
    const response = await fetch(`${SERVER_HOST}/medical-network?${urlParams}`);
    const data = await response.json();
    return data;
}

export const GetAllDistinct = async () => {
    const response = await fetch(`${SERVER_HOST}/get-all-distinct`);
    const data = await response.json();
    return data;
}
