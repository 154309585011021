import { Alert, AlertTitle } from "@mui/material";
import { useContext, useState } from "react";
import { MessengerChat } from "react-messenger-chat-plugin";
import LanguageContext from "../contexts/LanguageContext";

import "./Chat.css"

export default function Chat() {
    const lang = useContext(LanguageContext);
    const [chatError, setChatError] = useState(false);
    return (
      <div className="chat">
        <MessengerChat
          pageId="591260400919277"
          language="en_US"
          height={24}
          loggedInGreeting={
            lang === "en"
              ? "Welcome to OmegaCare, how may we help you today?"
              : "مرحبا بك في اوميجاكير، كيف يمكننا مساعدنك اليوم؟"
          }
          loggedOutGreeting={
            lang === "en"
              ? "Welcome to OmegaCare, how may we help you today?"
              : "مرحبا بك في اوميجاكير، كيف يمكننا مساعدنك اليوم؟"
          }
          autoExpand={true}
          debugMode={true}
          onMessengerLoad={() => {
            setTimeout(() => {
              let temp = document.getElementsByClassName("fb_dialog");
              console.log(temp)
              if (!temp.length)
                setChatError(true);
            }, 5000);
          }}
        />
        {chatError && (
          <Alert
            onClose={() => {
              setChatError(false);
            }}
            className="chatError"
            severity="error"
            dir={lang === "en"? "lrt" : "rtl"}
            style={{
              left: lang === "en" ? "24px" : "initial",
              right: lang === "ar" ? "24px" : "initial"
            }}
          >
            <AlertTitle>
              {lang === "en" ? "Live Chat" : "خدمة العملاء"}
            </AlertTitle>
            {lang === "en" ? "This website uses Facebook Messenger for customer support live chat, but your organization or network doesn't allow Facebook's domain to be reached. Please contact you network adminisrator or try again from a different network." : "هذا الموقع يستخدم فيسبوك ماسنجر للتواصل مع خدمة العملاء عبر الشات، ولكن مؤسستك أو شبكتك لاتسمح بالتواصل مع موقع فيسبوك. تواصل مع مدير شبكتك أو حاول مرة أخرى من شبكة أخرى."}
          </Alert>
        )}
      </div>
    );
  }