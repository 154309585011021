import "./AnimatedObjects.css"

export default function AnimatedObjects() {
  return (
    <>
      <div className="squares">
        <svg
          className="square"
          width="459"
          height="467"
          viewBox="0 0 459 467"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="square-grid">
            <rect
              id="Rectangle 11"
              x="0.000488281"
              y="59.967"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 0.000488281 59.967)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 12"
              x="106.711"
              y="43.8669"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 106.711 43.8669)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 13"
              x="213.422"
              y="27.7668"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 213.422 27.7668)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 14"
              x="320.133"
              y="11.6667"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 320.133 11.6667)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 15"
              x="16.5669"
              y="169.771"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 16.5669 169.771)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 16"
              x="123.278"
              y="153.671"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 123.278 153.671)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 17"
              x="229.989"
              y="137.571"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 229.989 137.571)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 18"
              x="336.7"
              y="121.471"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 336.7 121.471)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 19"
              x="33.1338"
              y="279.575"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 33.1338 279.575)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 20"
              x="139.845"
              y="263.475"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 139.845 263.475)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 21"
              x="246.556"
              y="247.375"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 246.556 247.375)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 22"
              x="353.267"
              y="231.275"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 353.267 231.275)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 23"
              x="49.7003"
              y="389.38"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 49.7003 389.38)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 24"
              x="156.412"
              y="373.28"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 156.412 373.28)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 25"
              x="263.123"
              y="357.18"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 263.123 357.18)"
              fill="#0ADAD0"
            />
            <rect
              id="Rectangle 26"
              x="369.834"
              y="341.08"
              width="78.2021"
              height="78.2021"
              transform="rotate(-8.57981 369.834 341.08)"
              fill="#0ADAD0"
            />
          </g>
        </svg>
      </div>
      <div className="circles">
        <svg
          className="hero-svg circle1"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="o" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
        <svg
          className="hero-svg circle2"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="oo" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
        <svg
          className="hero-svg circle3"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="ooo" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
      </div>
      <div className="circles right-circles">
        <svg
          className="hero-svg circle1"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="o" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
        <svg
          className="hero-svg circle2"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="oo" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
        <svg
          className="hero-svg circle3"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="ooo" cx="19" cy="19" r="19" fill="#0ADAD0" />
        </svg>
      </div>
      <div className="cross">
        <svg
          className="hero-svg x1"
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="x">
            <line
              id="Line 1"
              x1="3"
              y1="67.1716"
              x2="68.1716"
              y2="2"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <line
              id="Line 2"
              x1="2.82843"
              y1="2"
              x2="68"
              y2="67.1716"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </g>
        </svg>
        <svg
          className="hero-svg x2"
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="xx">
            <line
              id="Line 1"
              x1="3"
              y1="67.1716"
              x2="68.1716"
              y2="2"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <line
              id="Line 2"
              x1="2.82843"
              y1="2"
              x2="68"
              y2="67.1716"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </g>
        </svg>
      </div>
      <div className="cross right-cross">
        <svg
          className="hero-svg x1"
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="x">
            <line
              id="Line 1"
              x1="3"
              y1="67.1716"
              x2="68.1716"
              y2="2"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <line
              id="Line 2"
              x1="2.82843"
              y1="2"
              x2="68"
              y2="67.1716"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </g>
        </svg>
        <svg
          className="hero-svg x2"
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="xx">
            <line
              id="Line 1"
              x1="3"
              y1="67.1716"
              x2="68.1716"
              y2="2"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <line
              id="Line 2"
              x1="2.82843"
              y1="2"
              x2="68"
              y2="67.1716"
              stroke="#0ADAD0"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
}
