import React, { useContext, useState } from "react";
import LanguageContext from "../contexts/LanguageContext";
import "./AboutUs.css";
import { Fade } from "react-awesome-reveal";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import VisibilitySensor from "react-visibility-sensor";

import CountUp from "react-countup";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

const aboutUsData = require(`./../data/data.json`).aboutUs;
const mainBanner = require("../data/images.json")["banner_about_us"];
const visionBanner = require("../data/images.json")["banner_our_vision"];
const missionBanner = require("../data/images.json")["banner_our_mission"];
const aboutUsSvg = require("../data/images.json")["about_us_svg"];
const partnersImgs = require("../data/images.json")["partners_images"];

export default function AboutUs() {
  const lang = useContext(LanguageContext);
  const [shouldCount, setSouldCount] = useState([true, true, true, true, true]);
  var width = 1700;

  return (
    <main
      className="aboutUs"
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <section
        className="banner"
        style={{
          backgroundImage: `url(${mainBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{ height: "80vh" }}
          className="relative flex-container-global"
        >
          <h2>
            {lang === "en"
              ? "5 years of experience and still going"
              : "5 اعوام من الخبرة و لازالنا مستمرين"}
          </h2>
        </div>
      </section>
      <Fade>
        <section
          style={{ marginTop: "200px" }}
          className="flex-container-global about-contant"
        >
          <div
            style={{ justifyItems: lang === "en" ? "left" : "start" }}
            className="aboutUS"
          >
            <img
              className="svg1"
              src={aboutUsSvg[lang].about}
              alt=""
              srcSet=""
            />
            <img className="svg2" src={aboutUsSvg[lang].us} alt="" srcSet="" />
            <div className="about-text">
              <h2> {aboutUsData.S1[lang]} </h2>
              <p> {aboutUsData.S2[lang]} </p>
            </div>
          </div>
        </section>
      </Fade>

      <Fade duration={1500}>
        <section
          style={{ alignItems: "flex-start" }}
          className="flex-container-global temp "
        >
          <div className="temp-contant">
            <h2>{lang === "en" ? "OUR VISION" : "رؤيتنا"}</h2>
            <p>{aboutUsData.S3[lang]} </p>
          </div>
          <div className="temp-img">
            <img
              style={{
                width: `${width * 0.5}px`,
                height: `${width * 0.5 * 0.6}px`,
              }}
              src={visionBanner}
              alt=""
              srcSet=""
            />
          </div>
        </section>
      </Fade>
      <Fade duration={1500}>
        <section
          style={{ alignItems: "flex-start" }}
          className="flex-container-global temp"
        >
          <div className="temp-img">
            <img
              style={{
                width: `${width * 0.5}px`,
                height: `${width * 0.5 * 0.6}px`,
              }}
              src={missionBanner}
              alt=""
              srcSet=""
            />
          </div>
          <div className="temp-contant">
            <h2>{lang === "en" ? "OUR MISSION" : "مهمتنا"}</h2>
            <p>{aboutUsData.S4[lang]} </p>
          </div>
        </section>
      </Fade>
      <h1 style={{ textAlign: "center", margin: "1em 0" }}>
        {lang === "en"
          ? "Why choose OmegaCare"
          : "لماذا يجب أن تختار أوميجاكير"}
      </h1>
      <section className="flex-container-global stat-bg">
        <div className="stat-container">
          {aboutUsData.S5.map((e, i) => (
            <Fade key={i} delay={i * 150}>
              <div className="stat">
                <div className="num">
                  <h2>
                    <CountUp
                      redraw={true}
                      separator=","
                      end={e["num"]}
                      duration={3}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor
                          onChange={(e) => {
                            if (e && shouldCount[i]) {
                              shouldCount[i] = false;
                              setSouldCount(shouldCount);
                              start();
                            }
                          }}
                          delayedCall
                        >
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                </div>
                <div className="stat-text">
                  <h2>{e[lang]}</h2>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </section>
      <section
        style={{ flexDirection: "column" }}
        className="flex-container-global our-partners"
      >
        <h1 style={{ textAlign: "center" }}>
          {lang === "en" ? "Our Clients" : "بعض عملائنا"}
        </h1>
        <Swiper
          breakpoints={{
            1500: {
              width: 1500,
              slidesPerView: 3,
            },
            900: {
              width: 900,
              slidesPerView: 2,
            },
          }}
          slidesPerView={1}
          spaceBetween={5}
          loop={true}
          loopFillGroupWithBlank={false}
          autoplay={{
            delay: 4000,
          }}
          className="mySwiper"
        >
          {partnersImgs.map((img, i) => (
            <SwiperSlide className="flex" key={i}>
              <div className="swiper-img-container">
                <img src={img} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </main>
  );
}
