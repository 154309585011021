import React, { useEffect, useState, useContext } from "react";
import {Button, Pagination} from "@mui/material";
import "./MNTable.css";
import Loading from "./Loading";
import SearchBar from "./SearchBar";
import MNTableFilters from "./MNTableFilters";
import LanguageContext from "../contexts/LanguageContext";

import { GetAllDistinct, GetMedicalNetworkOld } from "../services/Server";

export default function MNTable() {
  let lang = useContext(LanguageContext);
  // distincts
  const [distincts, setDistincts] = useState(null);
  const [distinctsLoading, setDistinctsLoading] = useState(true);
  const [distinctsError, setDistinctsError] = useState(null);

  // MN table
  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const PAGE_SIZE = 10;
  const URL = `limit=${PAGE_SIZE}`;
  const [url, setUrl] = useState(`${URL}&offset=0&&`);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState("governorate");
  const [sortDir, setSortDir] = useState(1);
  const [filtersString, setFiltersString] = useState("");
  const [queryString, setQueryString] = useState("");
  const [search, setSearch] = useState("");



  const reset = () => {
    setFiltersString("");
    setQueryString("");
    setSearch("");
  };

  useEffect(() => {
    GetAllDistinct().then(res => {
      setDistincts(res);
      setDistinctsLoading(false);
    }).catch(err => {
      setDistinctsError(err);
      setDistinctsLoading(false);
    });
  }, []);

  useEffect(() => {
    GetMedicalNetworkOld(url).then(res => {
      setRawData(res);
      setLoading(false);
    }).catch(err => {
      setError(err);
      setLoading(false);
    });
  }, [url]);


  useEffect(() => {
    setUrl(
      `${URL}&offset=${PAGE_SIZE * pageNo}&${filtersString}&${queryString}`
    );
    console.log("rendering page: " + pageNo);
  }, [pageNo,filtersString, queryString, URL]);

  useEffect(() => {
    rawData &&
      setData(rawData.data.sort((a, b) => a[orderBy] > b[orderBy] ? sortDir : -sortDir));
    rawData && setDataCount(rawData.count);
  }, [rawData, orderBy, sortDir]); // pageNo

  return (
    <div className="MNTable">
      <div className="MNTable-header">
        {distinctsLoading && (
          <Loading
            text={
              lang === "en"
                ? "Loading medical network"
                : "جاري تحميل الشبكة الطبية"
            }
          />
        )}
        {distincts && (
          <>
            <MNTableFilters
              distincts={distincts}
              setFiltersString={setFiltersString}
            />
            <SearchBar
              value={search}
              setValue={setSearch}
              onEnter={(e) => {
                let t = search.replace(
                  /[^a-zA-Z0-9\u0621-\u064A\u0660-\u0669 @#%*]/g,
                  ""
                );
                setQueryString(`search=${t}&`);
              }}
              text={{ en: "Search...", ar: "البحث..." }}
            />
            <Button variant="contained" disableElevation onClick={reset}>
              {lang === "en" ? "Reset" : "إعادة للأصل"}
            </Button>
          </>
        )}
      </div>
      {loading && (
        <Loading
          text={
            lang === "en"
              ? "Loading medical network"
              : "جاري تحميل الشبكة الطبية"
          }
        />
      )}
      {(error || distinctsError) && (
        <div>
          <br />
          <br />

          <p>
            {
              "Failed to load some or all data, please check your internet connection and refresh this page."
            }
          </p>
          <br />
          <p>
            If you believe this to be a bug, please contact
            bugs@omegacare-med.net
          </p>
          <br />
          <br />
          <br />
          <p dir="rtl">
            {
              "لم يتم تحميل بعض أو كل المعلومات، برجاء التأكد من أتصالك بالأنترنت ثم إعادة تحميل الصفحة."
            }
          </p>
          <br />
          <p dir="rtl">
            إن كنت متأكد من وجود مشكلة فالموقع، برجاء التواصل معنا على
            bugs@omegacare-med.net.
          </p>
          <br />
        </div>
      )}
      {data && data.length !== 0 && (
        <div className="table-container">
          <table
            style={{
              direction: lang === "en" ? "lrt" : "rtl",
            }}
          >
            <thead>
              {/* add on click to sory by clicked category */}
              <tr>
                <th
                  onClick={() => {
                    setOrderBy("governorate");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Governorate" : "المحافظة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("region");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Region" : "المنطقة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_provider");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Provider" : "موفر لخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("branch_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Branch Type" : "نوع الفرع"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Type" : "نوع الخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("network_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Network Type" : "نوع الشبكة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("specialty");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Specialty" : "التخصص"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("level");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Level" : "المستوى"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("address");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Address" : "العنوان"}
                </th>
                {/* <th>Email</th> */}
                <th
                  onClick={() => {
                    setOrderBy("phone");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Phone" : "ارقام التيليفون"}
                </th>
                {/* <th>Phone 2</th> */}
                {/* <th>Notes</th> */}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item._id}>
                    <td>{item.governorate}</td>
                    <td>{item.region}</td>
                    <td>{item.service_provider}</td>
                    <td>{item.branch_type}</td>
                    <td>{item.service_type}</td>
                    <td>{item.network_type}</td>
                    <td>{item.specialty}</td>
                    <td>{item.level}</td>
                    <td>{item.address}</td>
                    {/* <td>{item.email}</td> */}
                    <td>{item.phone}</td>
                    {/* <td>{item.phone2}</td> */}
                    {/* <td>{item.notes}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          {/* add data navgation usting  */}
          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></div>
        </div>
      )}
      {(!data || data.length === 0) && (
        <p>{lang === "en" ? "No results available." : "لا نتائج متاحة."}</p>
      )}
      <div className="lower-table-section" dir="rtl">
        <Pagination
          size="small"
          count={Math.ceil(dataCount / PAGE_SIZE)}
          onChange={(e, value) => {
            setPageNo(value - 1);
          }}
          variant="outlined"
          dir="rtl"
          style={{ direction: "ltr" }}
        />
      </div>
    </div>
  );
}
