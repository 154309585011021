import React, { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";
import "./HomeSection.css";
import AnimatedObjects from "./AnimatedObjects";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const content = require(`./../data/data.json`).homeSection;

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default function HomeSection() {
  const lang = useContext(LanguageContext);

  return (
    <section className="homeSection" dir={lang === "en" ? "ltr" : "rtl"}>
      <div className="mainContainer">
        <AnimatedObjects />

        <img src="./img/hero-doctor.png" alt="" className="hero-img" />
        <div className="content">
          <Swiper
            slidesPerView={1}
            // spaceBetween={5}
            loop={true}
            loopFillGroupWithBlank={false}
            className="homeSectionSwiper"
            autoplay={{
              delay: 3000,
            }}
          >
            {content[lang].map((e, i) => (
              <SwiperSlide className="flex" key={i}>
                <div className="slideContainer">
                  <h1>{e.h1}</h1>
                  <p>{e.p}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="line"></div>
    </section>
  );
}
