import React, { useContext } from "react"; // useEffect, // useState,
import LanguageContext from "../contexts/LanguageContext";
import HMenu from "./HMenu";
import "./Header.css";
import { Link } from "react-router-dom";
import LangSwitch from "./LangSwitch";

const logo = require("../data/images.json")["new_logo_2"];

export default function Header({setLanguage}) {
  const lang = useContext(LanguageContext);
  let direction = lang === "ar" ? { direction: "rtl" } : {};
  const links = {
    en: ["HOME", "ABOUT US", "MEDICAL NETWORK", "NEWSLETTER", "CONTACT US", "SERVICES"],
    ar: ["الرئيسية", "من نحن", "الشبكة الطبية", "الاخبار", "تواصل معنا", "خدماتنا"],
    links: ["/", "/about", "/medical-network", "/newsletter", "/contact-us", "/services"],
  };
  return (
    <div className="header" style={{ ...direction }}>
      <div className="flex-container-global">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="links">
          <Link to="/">{links[lang][0]}</Link>
          <Link to="/about">{links[lang][1]}</Link>
          <Link to="/medical-network">{links[lang][2]}</Link>
          <Link to="/newsletter">{links[lang][3]}</Link>
          <Link to="/contact-us">{links[lang][4]}</Link>
          <Link to="/services">{links[lang][5]}</Link>
          <LangSwitch setLangauge={setLanguage}/>
        </div>
        <HMenu setLanguage={setLanguage} links={links} />
      </div>
    </div>
  );
}
