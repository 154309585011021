import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { FormGroup, FormControlLabel, Switch} from "@mui/material";
import LanguageContext from "../contexts/LanguageContext";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(/img/ar.svg)`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(/img/en.svg)`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function LangSwitch({ setLangauge }) {
  var lang = useContext(LanguageContext);
  const switchLang = () => {
    let newLang = lang === "en" ? "ar" : "en";
    setLangauge(newLang);
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={lang === "en"} />}
        label=""
        onChange={switchLang}
        style={{ margin: 0 }}
      />
    </FormGroup>
  );
}
