import React, { useContext } from "react";
import "./Advantages.css";
import LanguageContext from "../contexts/LanguageContext";
import ISOImg from "./../assets/ISO.png";
import callCenterImg from "./../assets/callCenter.svg";
import mobileAppImg from "./../assets/mobileApp.svg";
import mapsImg from "./../assets/maps.svg";
import { Fade } from "react-awesome-reveal";
function Advantage({ img, text, i }) {
  return (
    <Fade duration={1000} delay={i * 150}>
      <div className="advantage">
        <img src={img} alt="advantage" />
        <p>{text}</p>
      </div>
    </Fade>
  );
}

const text = {
  en: {
    title: "OMEGACARE ADVANTAGES",
    0: "ISO Certified",
    1: "Active Call Center",
    2: "Mobile App",
    3: "Wide Range of Coverage",
  },
  ar: {
    title: "مميزات اوميجا كير",
    0: "شهادة ايزو موثقة",
    1: "سنتر خدمة عملاء سريع",
    2: "تطبيق موبايل",
    3: "تغطية واسعة وشاملة",
  },
};

export default function Advantages() {
  const lang = useContext(LanguageContext);

  let advantageImgs = [ISOImg, callCenterImg, mobileAppImg, mapsImg];

  let advantages = [];
  for (let i = 0; i < 4; i++) {
    advantages[i] = (
      <Advantage key={i} i={i} img={advantageImgs[i]} text={text[lang][i]} />
    );
  }

  return (
    <div className="advantages">
      <h1 style={{ textAlign: "center" }}>
        {lang === "en" ? "WHY CHOOSE OMEGACARE" : "مميزات اوميجاكير"}
      </h1>
      <div className="content">{advantages}</div>
    </div>
  );
}
