import React, { useContext, useState, useEffect } from "react";
import LangaugeContext from "../contexts/LanguageContext";
import HomeSection from "../components/HomeSection";
import PlanCard from "../components/PlanCard";
import Advantages from "../components/Advantages";
import HomePageButtomSlider from "../components/HomePageBottomSlider";
import { Fade } from "react-awesome-reveal";

import Post from "../components/Post";
import Loading from "../components/Loading";

import Button from "@mui/material/Button";


import "./HomePage.css";

import { GetNews } from "../services/Server";

const plans = require("../data/plans.json");
const aboutUsSvg = require("../data/images.json")["about_us_svg"];
const aboutUsData = require(`./../data/data.json`).aboutUs;

export default function HomePage() {
  const lang = useContext(LangaugeContext);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    GetNews().then(res => {
      setPosts(res);
      setLoading(false);

    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError(err);
    });

  }, []);


  return (
    <div dir={lang === "en" ? "ltr": "rtl"} className="homePage">
      <HomeSection />
      <section
        style={{ flexDirection: "column" }}
        className="flex-container-global our-partners"
      >
        <h1 style={{ textAlign: "center", fontSize: "2em" }}>
          {lang === "en" ? "OUR LATEST NEWS" : "آخر أخبارنا"}
        </h1>
        {loading && (
          <Loading
            text={lang === "en" ? "Loading news" : "جاري تحميل الأخبار"}
          />
        )}
        {error && (
          <div>
            <br />
            <br />

            <p>
              {
                "Failed to load some or all data, please check your internet connection and refresh this page."
              }
            </p>
            <br />
            <p>
              If you believe this to be a bug, please contact
              bugs@omegacare-med.net
            </p>
            <br />
            <br />
            <br />
            <p dir="rtl">
              {
                "لم يتم تحميل بعض أو كل المعلومات، برجاء التأكد من أتصالك بالأنترنت ثم إعادة تحميل الصفحة."
              }
            </p>
            <br />
            <p dir="rtl">
              إن كنت متأكد من وجود مشكلة فالموقع، برجاء التواصل معنا على
              bugs@omegacare-med.net.
            </p>
            <br />
          </div>
        )}
        {posts &&
          posts.map((post, i) => {
            return <Post key={i} data={post} />;
          })}
          <Button
          className="btn"
          href="/newsletter"
          variant="contained"
          disableElevation
          style={{ backgroundColor: `var(--primary-color1)` }}
        >
          {lang === "en" ? "Our Full Newsletter" : "الأخبار كاملة"}
        </Button>
      </section>
      <section className="flex-container">
        <div className="flex-container-global mid-section">
          <h1 style={{ color: `var(--primary-color1)` }}>
            {lang === "en" ? "OUR PLANS" : "خدماتنا"}
          </h1>
          <Fade>
            <div className="plans">
              <PlanCard i={0} lang={lang} plan={plans[0]} key={plans[0].id} />
            </div>
          </Fade>
        </div>
        <Button
          className="btn"
          href="/services"
          variant="contained"
          disableElevation
          style={{ backgroundColor: `var(--primary-color1)` }}
        >
          {lang === "en" ? "Check All Plans" : "تفقد كامل الخدمات"}
        </Button>
      </section>
      <Fade>
        <section className="flex-container">

          <div
            style={{ marginTop: "100px" }}
            className="flex-container-global about-contant"
          >
            <div
              style={{ justifyItems: lang === "en" ? "left" : "start" }}
              className="aboutUS"
            >
              <img
                className="svg1"
                src={aboutUsSvg[lang].about}
                alt=""
                srcSet=""
              />
              <img className="svg2" src={aboutUsSvg[lang].us} alt="" srcSet="" />
              <div className="about-text">
                <h2> {aboutUsData.S1[lang]} </h2>
                <p> {aboutUsData.S2[lang]} </p>
              </div>
            </div>
          </div>
          <Button
            className="btn"
            href="/about"
            variant="contained"
            disableElevation
            style={{ backgroundColor: `var(--primary-color1)` }}
          >
            {lang === "en" ? "Read more" : "اقرأ المزيد"}
          </Button>
        </section>
      </Fade>

      <Advantages />
      <HomePageButtomSlider />
    </div>
  );
}
