import React, { useContext } from "react";
import "./PlanCard.css";
import Button from "@mui/material/Button";
import LanguageContext from "../contexts/LanguageContext";
import { Slide } from "react-awesome-reveal";

export default function PlanCard({ plan, i }) {
  let lang = useContext(LanguageContext);
  const { title, phrase, details } = plan[lang];
  const { img, id } = plan;

  return (
    <Slide direction={i%2!==0 ? "right" : "left"} triggerOnce>
      <div className="planCard " dir={lang === "en" ? "ltr" : "rtl"}>
        <img src={img} alt="card img"></img>
        <div className="content">
          <h3 className="title">{title}</h3>
          <p className="phrase">{phrase}</p>
          <p className="details">{details}</p>
          <div className="bottom">
            <p>
              <span>
                {lang === "en"
                  ? "Want to know more? Contact us now!"
                  : "تريد أن تعرف المزيد؟ تواصل معنا الآن"}
              </span>
            </p>
            <Button
              className="btn"
              href="/contact-us"
              variant="contained"
              disableElevation
              style={{ backgroundColor: `var(--plan${id})` }}
            >
              {lang === "en" ? "Let's Talk" : "تحدث معنا"}
            </Button>
          </div>
        </div>
      </div>
    </Slide>
  );
}
