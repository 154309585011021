import React from "react";
import "./Loading.css";

export default function Loading({ text = "" }) {
  return (
    <div className="loading">
      <div className="dna-loader-3d" style={{ "--count": "30;" }}>
        <div className="orbit" style={{ "--i": "1" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "2" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "3" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "4" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "5" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "6" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "7" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "8" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "9" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "10" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "11" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "12" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "13" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "14" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "15" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "16" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "17" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "18" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "19" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "20" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "21" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "22" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "23" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "24" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "25" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "26" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "27" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "28" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "29" }}>
          <div className="ball"></div>
        </div>
        <div className="orbit" style={{ "--i": "30" }}>
          <div className="ball"></div>
        </div>
      </div>
      <p className="text">{text}</p>
    </div>
  );
}
