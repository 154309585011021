import React, { useContext } from "react";
import "./Services.css";
import LanguageContext from "../contexts/LanguageContext";
import OurPlans from "../components/OurPlans";


const imgBanner = require("../data/images.json")["banner_services"];

export default function Services() {
  const lang = useContext(LanguageContext);
  const direction = lang === "ar" ? "rtl" : "ltr";
  return (
    <main className="ourServices" style={{ direction: direction }}>
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          minHeight: "80vh",
        }}
      >
        <div className="container">
          <h1 className="title">
            {lang === "en" ? "Our Services" : "خدمتنا"}
          </h1>
          <p className="">
            {lang === "en"
              ? "ًًWe offer a wide range of the finest quality medical care services."
              : "نحن في أوميجا كير نقدم لك نطاق واسع من الخدمات الطبية ذات الجودة الفائقة."}
          </p>
        </div>
      </section>
      <section className="content">
        <OurPlans />
      </section>
    </main>
  );
}
